import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/rileyuy/Documents/GitHub/ep-mg-website/src/components/Layout/Layout.js";
import { Link } from "gatsby";
import Container from "layout/Container";
import Collapsible from "elements/Collapsible";
import SEO from "layout/SEO";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container isCentered mdxType="Container">
      <SEO title="Terms and Conditions" mdxType="SEO" />
      <h2>{`Terms and Conditions`}</h2>
      <hr />
      <div className="content is-medium">
        <div className="message">
          <div className="message-body">
            <p><a parentName="p" {...{
                "href": "https://medgrocer.com"
              }}>{`https://medgrocer.com`}</a>{` (“Website”) is an internet-based platform that facilitates the online sale and purchase of pharmaceutical products and services offered by MG Health Solutions Inc. (“MedGrocer”), a company duly authorized by the Food and Drug Administration (FDA) of the Philippines to operate as a retailer of such products.`}</p>
          </div>
        </div>
        <h2>{`Content`}</h2>
        <p>{`The contents of the Website, information, text, graphics, images, logos, icons, design, and the collection, arrangement, and assembly of content on the Website (collectively, “MedGrocer Content”), are the property of MedGrocer. The User shall not modify the MedGrocer Content or reproduce, display, publicly perform, distribute, or otherwise use the MedGrocer content in any way for any public or commercial purpose or for personal gain.`}</p>
        <p>{`MedGrocer Content may communicate basic information about medications, supplements, pharmaceuticals, and other personal or health care products that may not be complete, up-to-date, or free from errors or omissions. MedGrocer may make changes or improvements at any time.`}</p>
        <p>{`The content of the Website, including without limitation, text, copy, audio, video, photographs, illustrations, graphics and other visuals, is for informational purposes only and is not intended to be used as substitute or replacement for professional medical advice, diagnosis, treatment, or recommendations of any kind. Individual medical concerns should be consulted with qualified health care professionals before taking any form of treatment, medication, or supplement. Reliance on any information appearing on the Website is solely at the User’s own risk.`}</p>
        <p>{`MedGrocer advises the User to always seek the advice of a doctor or any qualified healthcare professional with any questions regarding personal health or medical conditions. The User should never disregard, avoid, or delay in obtaining medical advice from a doctor or other qualified healthcare professional because of information obtained from the Website or from MedGrocer representatives.
Retail prices on the Website are subject to change without prior notice. All prices are inclusive of Value Added Tax (VAT) and are expressed in Philippine Peso. The User recognizes that pricing errors may occur, and it is the responsibility of the User to review product prices during Order Confirmation.`}</p>
        <p>{`MedGrocer Content is provided “as is” and without warranties of any kind either expressed or implied, to the fullest extent permissible pursuant to applicable law. MedGrocer does not warrant or make any representations regarding the use of or the result of the use of the content of the Website in terms of their correctness, accuracy, reliability, or otherwise. MedGrocer also disclaims all liability resulting to loss, damage, personal injury, death, fraud, or other consequences arising from the use of the Website or services offered by MedGrocer.`}</p>
        <h2>{`Order and Delivery`}</h2>
        <Collapsible title="Order Placement" mdxType="Collapsible">
          <p>{`To place an Order through the Website, the User must add a product to the cart and complete the checkout process. To place an Order through other channels, the User must contact MedGrocer by means of electronic mail to orders@medgrocer.com.`}</p>
        </Collapsible>
        <Collapsible title="Order Processing" mdxType="Collapsible">
          <p>{`Orders are processed from Monday to Friday (“Working Days”), from 10AM to 6PM only. Orders placed beyond this schedule are processed on the nearest working day. All orders received by MedGrocer are subject to order processing by a MedGrocer Pharmacist.`}</p>
        </Collapsible>
        <Collapsible title="Orders with Prescription Products" mdxType="Collapsible">
          <p>{`After Order Processing, the User shall expect a text from a MedGrocer pharmacy assistant to confirm Order details such as product/s ordered and respective dosage form, strength, and quantity. Delivery information such as the User’s name and address shall also be verified. The User shall be responsible for ensuring the accuracy of the Order and Delivery details. MedGrocer shall not be obliged to dispense any products until the Order has been confirmed.`}</p>
        </Collapsible>
        <Collapsible title="Payment" mdxType="Collapsible">
          <p>{`MedGrocer accepts Orders to be delivered within the Metro Manila area only.`}</p>
          <p>{`MedGrocer reserves the right, in its sole discretion, without limitations and/or conditions, to refuse any service to any customer for any reason attributable to the unavailability of the products ordered, or other circumstances and conditions beyond the control of MedGrocer which would delay or render impossible the fulfilment of its obligations.`}</p>
        </Collapsible>
        <Collapsible title="Delivery Schedule" mdxType="Collapsible">
          <p>{`From the time of successful Order Confirmation, the User may expect a MedGrocer Pharmacy Assistant to deliver the purchased products within the next 24 to 48 hours. Inclement weather, natural calamities, and other events beyond MedGrocer’s control may affect the delivery schedule.`}</p>
        </Collapsible>
        <Collapsible title="Delivery Schedule" mdxType="Collapsible">
          <p>{`Delivery Area Coverage
MedGrocer accepts Orders to be delivered within the Metro Manila area only.`}</p>
        </Collapsible>
        <Collapsible title="Delivery Receipt" mdxType="Collapsible">
          <p>{`Purchased products are dispensed in sealed packages prior to delivery. Upon receipt of the package, it is the User’s or an authorized representative’s responsibility to inspect the packaging to ensure that it has not been tampered with. To acknowledge receipt of the package, the User or authorized representative shall be asked to sign a delivery receipt.`}</p>
        </Collapsible>
        <Collapsible title="Cancellation" mdxType="Collapsible">
          <p>{`If the User wishes to cancel an Order prior to dispensing, a notice must be sent to the MedGrocer hotline, 0917 846 1677. MedGrocer, however, reserves the right to refuse cancellation in the case that the Order has already been dispensed or dispatched for delivery prior to the receipt of the User’s request to cancel.`}</p>
        </Collapsible>
        <h2>{`Promotions`}</h2>
        <Collapsible title="Eligibility" mdxType="Collapsible">
          <ol>
            <li parentName="ol">
              <p parentName="li">{`Promotional Coupons, in the form of coupon codes, may be issued by MedGrocer from time to time. These may include discounts, free products, and/or services. Store Credits may also be issued for certain promotions, client programs, or in exchange for returned items.`}</p>
            </li>
            <li parentName="ol">
              <p parentName="li">{`MedGrocer may email Promotional Coupons and Store Credits to the User. MedGrocer accepts no liability for errors in the email address of the recipient. MedGrocer assumes no liability for the loss, theft, or ineligibility of Promotional Coupons and Store Credits.`}</p>
            </li>
            <li parentName="ol">
              <p parentName="li">{`Promotional Coupons and Store Credits may not be used in conjunction with government mandated discounts such as senior citizen’s discount or PWD discount unless otherwise specified by the promotion mechanics.`}</p>
            </li>
            <li parentName="ol">
              <p parentName="li">{`For Promotional Coupons offering discounts to First-time Users, offer is only valid if User meets the following conditions:`}</p>
              <ul parentName="li">
                <li parentName="ul">
                  <p parentName="li">{`The email address linked to the User’s Facebook or Google account is unique;`}</p>
                </li>
                <li parentName="ul">
                  <p parentName="li">{`The shipping address used is unique and is not used for another User account;`}</p>
                </li>
                <li parentName="ul">
                  <p parentName="li">{`No successfully completed Order has been recorded yet for the User’s account.`}</p>
                </li>
              </ul>
            </li>
            <li parentName="ol">
              <p parentName="li">{`For Promotional Coupons offering discounts for prescription medicines, offer is only valid if User meets the following conditions:`}</p>
              <ul parentName="li">
                <li parentName="ul">
                  <p parentName="li">{`The User uploads a valid prescription on the website;`}</p>
                </li>
                <li parentName="ul">
                  <p parentName="li">{`The prescription used is unique, has not been previously used by the User for the same or any other promo, and has not been used by any other User account;`}</p>
                </li>
                <li parentName="ul">
                  <p parentName="li">{`The User presents no more than one (1) prescription where the promo will be applied;`}</p>
                </li>
                <li parentName="ul">
                  <p parentName="li">{`The User’s residential address is used only once in availing of the promo and is not linked to any other User availing of the same or other MedGrocer promos.`}</p>
                </li>
              </ul>
            </li>
            <li parentName="ol">
              <p parentName="li">{`MedGrocer may request an official ID to verify details provided by the User.`}</p>
            </li>
            <li parentName="ol">
              <p parentName="li">{`In the event of fraud, deception, or suspicion of illegal activities in connection with a Promotional Coupon or Store Credit purchase, MedGrocer is entitled to reject the Promotional Coupon or Store Credit, close the User’s account, and/or require different means of payment.`}</p>
            </li>
            <li parentName="ol">
              <p parentName="li">{`MedGrocer has the right to reject any voucher that has been tampered with or found in any way unacceptable.`}</p>
            </li>
          </ol>
        </Collapsible>
        <Collapsible title="Mechanics" mdxType="Collapsible">
          <ol>
            <li parentName="ol">
              <p parentName="li">{`Promotional Coupons and Store Credits may be used as payment for products purchased from MedGrocer.`}</p>
            </li>
            <li parentName="ol">
              <p parentName="li">{`Promotional Coupons and Store Credits are valid on the specified period, can only be redeemed a specified number of times, and cannot be used in conjunction with other Promotional Coupons. Individual products may be excluded from certain promotions.`}</p>
            </li>
            <li parentName="ol">
              <p parentName="li">{`If the User places an Order for a product less than the value of the Promotional Coupon or Store Credit, no refund or residual credit will be returned to the User.`}</p>
            </li>
            <li parentName="ol">
              <p parentName="li">{`The credit of a Promotional Coupon or Store Credit does not accrue interest nor does it have a cash value.`}</p>
            </li>
            <li parentName="ol">
              <p parentName="li">{`The value of the Promotional Coupon or Store Credit covers only the price of the products in the Order. Any additional fees and charges incurred in connection with the Order, including convenience fee and additional taxes, shall be paid by the User.`}</p>
            </li>
            <li parentName="ol">
              <p parentName="li">{`If the credit from the Promotional Coupon or Store Credit is insufficient for the Order, the User may make up the difference through payment.`}</p>
            </li>
            <li parentName="ol">
              <p parentName="li">{`MedGrocer reserves the right to change mechanics without prior notice.`}</p>
            </li>
            <li parentName="ol">
              <p parentName="li">{`All Promotional Coupon or Store Credit redemptions must comply with SCPWD government regulations.`}</p>
            </li>
          </ol>
        </Collapsible>
        <h2>{`Privacy Policy`}</h2>
        <ol>
          <li parentName="ol">
            <p parentName="li">{`MedGrocer is also responsible for maintaining and protecting any Personal Information under its control. MedGrocer ensures that all information collected will be safely and securely stored.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Personal Information MedGrocer collects from the User will be used, or shared with third parties (including related companies and third party service providers) for some or all of the following purposes:`}</p>
            <ul parentName="li">
              <li parentName="ul">
                <p parentName="li">{`to facilitate the the use of services and/or access to the Website,`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`to process orders,`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`to deliver products purchased,`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`to update the User on the status of the Order,`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`to compare information and to verify if such information is accurate,`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`to administer an account with MedGrocer, audit Users’ demographics and behavior, provide information MedGrocer deems might be useful for the User or
information the User requested (including information from third parties),`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`to send marketing and/or promotional materials about MedGrocer’s or third party’s products and services from time to time and to send newsletters from MedGrocer or other related companies,`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`and in exceptional circumstances, to disclose Personal Information when there are grounds to believe that the disclosure is necessary to prevent a threat to life/health, for law enforcement purposes, or for fulfillment of legal and regulatory requirements or requests.`}</p>
              </li>
            </ul>
          </li>
        </ol>
        <p>{`If, for any reason, the User’s Personal Information is required to fulfill a different purpose, MedGrocer will notify the User and ask for consent.`}</p>
        <ol {...{
          "start": 3
        }}>
          <li parentName="ol">{`Information that could identify a User personally will not be collected upon visiting the Website unless the User chooses to provide information voluntarily. Users are welcome to browse the Website at any time anonymously and privately without revealing any Personal Information.`}</li>
        </ol>
        <h2>{`Limitation of Liability`}</h2>
        <Collapsible title="General" mdxType="Collapsible">
          <ol>
            <li parentName="ol">
              <p parentName="li">{`MedGrocer shall not be responsible or liable in any manner to the User for any losses, damage, injuries, or expenses incurred by the User as a result of any actions or decisions taken as a result of using the Website or services offered by MedGrocer`}</p>
            </li>
            <li parentName="ol">
              <p parentName="li">{`In no event shall MedGrocer, or any of its directors, officers, employees, agents, or content or service providers be liable for any direct, indirect, special, incidental, consequential, exemplary or punitive damages arising from, or directly or indirectly related to the use of or the inability to use the
Website or the content, materials, and functions relations thereto and the User’s provision of information via the Website. In no event shall such be liable for lost business or lost sales, even if the User has been advised of the possibility of such damages. In no event shall MedGrocer be liable for any content posted, transmitted, exchanged, or received by or on behalf of any User or other person on or through the Website.`}</p>
            </li>
          </ol>
        </Collapsible>
        <Collapsible title="Products on MedGrocer" mdxType="Collapsible">
          <p>{`MedGrocer follows the stringent requirements of the Food and Drug Administration (FDA) in terms of storage, handling, and dispensing of the products. MedGrocer is therefore not liable for any damages (side effects, adulteration, etc.) related to the use of any products offered.`}</p>
        </Collapsible>
        <Collapsible title="Third-party Websites" mdxType="Collapsible">
          <p>{`The Website may be linked to the website of third parties, affiliates, and business partners. MedGrocer has no control over and accept no responsibility for the content of any site to which a link from the Website exists. Such linked sites are provided “as is” for the User’s convenience with no warranty, express or implied, for the information provided within them. Inclusion of any link on the Website does not imply that MedGrocer endorses the linked site. The User may use the links and these services at the User’s own risk. The User must not, without permission from MedGrocer, frame any of the Website onto another website.`}</p>
        </Collapsible>
        <h2>{`Contact`}</h2>
        <ol>
          <li parentName="ol">
            <p parentName="li">{`If the User has any questions, issues, or complaints regarding any of MedGrocer’s services, please contact `}<a href="mailto:order@medgrocer.com" target="_blank" rel="noopener noreferrer">{` order@medgrocer.com `}</a>{` or 0917 846 1677.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`If the User has any questions concerning MedGrocer, the Website, these Terms and Conditions, or anything related to any of the foregoing, MedGrocer can be reached at the email address `}<a href="mailto:order@medgrocer.com" target="_blank" rel="noopener noreferrer">{` order@medgrocer.com `}</a>{`, or through the MedGrocer hotline, 0917 846 1677.`}</p>
          </li>
        </ol>
      </div>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      